<template>
  <div class="difference-letters-game">
    <h1>Difference Letters Game</h1>
    <div class="game-container">
      <div class="round-informations">
        <p>Round: {{gameRound}}</p>
        <p>Timer: {{currentTimer}} seconds</p>
      </div>
      <div class="game-content">
        <button v-if="!isGameStarted" class="start-button" @click="startGame()">Game start</button>
        <table v-if="isGameStarted">
          <tbody>
            <tr v-for="(row, rowIndex) in displayTable" v-bind:key="rowIndex">
              <td v-for="(col, colIndex) in row" v-bind:key="colIndex" @click="checkResult(rowIndex, colIndex)">{{col}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "difference-letters-game",
  data() {
    return {
      isGameStarted: false,
      gameRound: 1,
      currentTimer: 11,
      timerInterval: {},
      tableSize: 2,
      displayTable: [],
      correctRowIndex: 1,
      correctColIndex: 1,
    }
  },
  methods: {
    getClientInfo() {
      //TODO:
    },
    getRandomLetterPair() {
      let letters = ['ZS', 'SZ', 'IJ', 'JI', '5S', 'S5', 'QO', 'OQ', 'pb', 'bp', '69', '96', 'RP', 'PR', 'tf', 'ft','0O','O0'];
      let randomIndex = Math.floor(Math.random() * letters.length);
      return letters[randomIndex];
    },
    generateTable() {
      this.displayTable = [];
      let diffLetter = this.getRandomLetterPair();
      //Random letters
      this.correctRowIndex = Math.floor(Math.random() * this.tableSize);
      this.correctColIndex = Math.floor(Math.random() * this.tableSize);
      for (var i = 0; i < this.tableSize; i++) {
        let row = [];
        for (var j = 0; j < this.tableSize; j++) {
          if (i == this.correctRowIndex && j == this.correctColIndex) { 
            row.push(diffLetter[1]);
          } else {
            row.push(diffLetter[0]);
          }
        }
        this.displayTable.push(row);
      }
    },
    startGame() {
      this.gameRound = 1;
      this.tableSize = 2;
      this.generateTable();
      this.isGameStarted = true;
      this.timerInterval = setInterval(() => {
        if (this.currentTimer > 0) {
          this.currentTimer = this.currentTimer - 1;
        } else {
          //Time up
          clearInterval(this.timerInterval);
          this.currentTimer = 11;
          this.isGameStarted = false;
          this.tableSize = 2;
          alert("Game over! You got " + (this.gameRound - 1) + " rounds!");
        }
      }, 1000);
    },
    checkResult(rowIndex, colIndex) {
      //Stop the timer
      clearInterval(this.timerInterval);
      //Reset the timer
      this.currentTimer = 11;
      if (rowIndex == this.correctRowIndex && colIndex == this.correctColIndex) {
        //next round;
        this.gameRound++;
        if (this.gameRound % 3 == 0 && this.tableSize < 15) {
          this.tableSize++;
        } else if (this.gameRound > 7 && Math.round(Math.random()*1)>0) {
          let randomNumber = Math.round(Math.random() * 2,0);
          if (this.tableSize - randomNumber > 4) {
            this.tableSize =  this.tableSize - randomNumber;
          }
        }
        this.generateTable();
        this.timerInterval = setInterval(() => {
          if (this.currentTimer > 0) {
            this.currentTimer = this.currentTimer - 1;
          } else {
            //Time up
            clearInterval(this.timerInterval);
            this.currentTimer = 11;
            this.isGameStarted = false;
            this.tableSize = 2;
            alert("Game over! You got " + (this.gameRound - 1) + " rounds!");
          }
        }, 1000);
      } else {
        //Game over
        this.isGameStarted = false;
        this.tableSize = 2;
        alert("Game over! You got " + (this.gameRound - 1) + " rounds!");
      }
    }
  },
  mounted: function() {
    this.getClientInfo();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.login-image {
  margin-top: 10%;
  width: 270px;
}
.footer {
  position: absolute;
  right: 0;
  left: 0;
  padding: 1rem;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}
.start-button {
  margin-top: 10%;
  height: 50px;
  color: black;
  background-color: rgb(122, 122, 167);
  widows: 50px;
  cursor: pointer;
}
.game-container table{
  color: white;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
}
.game-container table td{
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  font-size: 20px;
}
</style>
