<template>
  <div class="memories-game">
    <h1>Memories Game</h1>
    <div class="game-container">
      <div class="round-informations">
        <p>Round: {{gameRound}}</p>
      </div>
      <div>
        Level
        <button :disabled="isGameStarted" :class="{'button-active': level==1}" @click="level = 1; tableSize = 2; getHighScore();">Easy</button>
        <button :disabled="isGameStarted" :class="{'button-active': level==2}" @click="level = 2; tableSize = 4; getHighScore();">Medium</button>
        <button :disabled="isGameStarted" :class="{'button-active': level==3}" @click="level = 3; tableSize = 8; getHighScore();">Hard</button>
      </div>
      <div>
        <p><button :disabled="!isGameStarted" @click="resetGame()">Reset Game</button></p>
      </div>
      <div class="game-content">
        <button v-if="!isGameStarted" class="start-button" @click="startGame()">Game start</button>
        <p v-show="isGameStarted">Time play: {{timeplay}} seconds</p>
        <table v-if="isGameStarted" :class="{'congratulation': nextRoundFlag, 'game-over': gameOverFlag}">
          <thead>
            <tr>
              <th :colspan="displayTable.length">{{displayMsg}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in displayTable" v-bind:key="rowIndex">
              <td v-for="(col, colIndex) in row" v-bind:key="colIndex" @click="checkResult(rowIndex, colIndex)" 
              :name="col.id" :class="{'td200': tableSize == 2, 'td100': tableSize == 4, 'td50': tableSize == 8, 
              'lighting-up': col.isLightingUp, 'is-clicked-correct': col.isClickedCorrect, 'is-clicked-error': col.isClickedError}"></td>
            </tr>
            <tr>
              <td :colspan="displayTable.length"><button v-if="isAgainShow" style="background-color: #cdcdcd; font-size: 13px; cursor: pointer;" @click="executeFirstState(); correctSteps = [].concat(currCorrectSteps);" :disabled="isAgainDisabled">Hint: I'm NOT follow???</button></td>
            </tr>
          </tbody>
        </table>
        <table id="highscores">
          <thead>
            <tr>
              <th colspan="5">High Score Level {{ level == 1 ? "Easy" : level == 2 ? "Medium" : "Hard"}}</th>
            </tr>
            <tr>
              <th>No</th>
              <th>Gamer</th>
              <th>Round</th>
              <th>Timeplay</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="highScores.length <= 0">
              <td colspan="5">{{highScoreMsg}}</td>
            </tr>
            <tr v-for="(row, index) in highScores" v-bind:key="index">
              <td>{{index + 1}}</td>
              <td>{{row.username}}</td>
              <td>{{row.round}}</td>
              <td>{{row.timeplay}}</td>
              <td>{{row.timeRecord}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import httpRequest from '../../services/httpRequest.js';

export default {
    name: "memories-game",
    data() {
        return {
            isGameStarted: false,
            gameRound: 1,
            level: 1,
            tableSize: 2,
            startGameTime: 0,
            timeplay: 0,
            intervalGameStart: {},
            displayTable: [],
            numberOfSteps: 1,
            correctSteps: [],
            currCorrectSteps: [],
            nextRoundFlag: false,
            gameOverFlag: false,
            clickEnabled: false,
            isAgainShow: false,
            isAgainDisabled: false,
            displayMsg: "Welcome to the game!",
            highScores: [],
            highScoreMsg: "Loading the highscores..."
        };
    },
    methods: {
        getClientInfo() {
            //TODO:
        },
        getRandomLogics() {
            //Random steps
            for (var i = 0; i < this.numberOfSteps; i++) {
                let correctRowIndex = Math.floor(Math.random() * this.tableSize);
                let correctColIndex = Math.floor(Math.random() * this.tableSize);
                this.correctSteps.push(correctRowIndex + "" + correctColIndex);
            }
            this.currCorrectSteps = [].concat(this.correctSteps);
        },
        executeFirstState() {
            let that = this;
            let timeOut = Math.ceil(2000/this.level);
            let flashTimeOut = Math.ceil(1000/this.level);
            let startTimeOut = 2000;
            this.isAgainShow = true;
            this.isAgainDisabled = true;
            this.displayMsg = "Please remember the step of lighting.";
            this.clickEnabled = false;
            for (var i = 0; i < this.currCorrectSteps.length; i++) {
                let stepRow = parseInt(this.currCorrectSteps[i][0]);
                let stepCol = parseInt(this.currCorrectSteps[i][1]);
                setTimeout(() => that.displayTable[stepRow][stepCol].isLightingUp = true, startTimeOut);
                if (i == this.currCorrectSteps.length - 1) {
                    setTimeout(() => {
                      that.displayTable[stepRow][stepCol].isLightingUp = false; 
                      setTimeout(() => {
                        that.displayMsg = "Your turn:  "; 
                        that.clickEnabled = true;                         
                        that.isAgainDisabled = false;
                      }, 1000);
                    }, startTimeOut + flashTimeOut);
                }
                else {
                    setTimeout(() => that.displayTable[stepRow][stepCol].isLightingUp = false, startTimeOut + flashTimeOut);
                }
                startTimeOut += timeOut;
            }
        },
        generateTable() {
            let that = this;
            this.displayTable = [];
            for (var i = 0; i < this.tableSize; i++) {
                let row = [];
                for (var j = 0; j < this.tableSize; j++) {
                    row.push({
                        id: i + "" + j,
                        isLightingUp: false,
                        isClickedCorrect: false,
                        isClickedError: false
                    });
                }
                this.displayTable.push(row);
            }
            this.getRandomLogics();
            setTimeout(() => {
                that.executeFirstState();
            }, 2000);
        },
        startGame() {
            let that = this;
            this.gameRound = 1;
            this.numberOfSteps = 1;
            this.displayMsg = "Welcome to the game!";
            this.isAgainDisabled = false;
            this.generateTable();
            this.isGameStarted = true;
            this.startGameTime = new Date().getTime();
            this.intervalGameStart = setInterval(() => that.timeplay = Math.round(((new Date()).getTime() - that.startGameTime) / 1000), 1000);
        },
        checkResult(rowIndex, colIndex) {
            let that = this;
            if (this.clickEnabled) {
                let correctStep = this.correctSteps.shift();
                if (correctStep[0] != rowIndex || correctStep[1] != colIndex) {
                    this.isAgainDisabled = true;
                    clearInterval(this.intervalGameStart);
                    this.displayMsg = "Game Over!";
                    this.displayTable[rowIndex][colIndex].isClickedError = true;
                    this.gameOverEffect();
                    setTimeout(() => {
                      that.displayMsg = "Game over! You got " + (that.gameRound - 1) + " rounds!";
                      let lastHighScore = that.highScores.length > 0 ? that.highScores[that.highScores.length - 1] : {};
                      if (that.highScores.length < 10 || that.gameRound > lastHighScore.round || (that.gameRound == lastHighScore.round && that.timeplay < lastHighScore.timeplay)) {
                        that.saveHighScore();
                      }
                    }, 500);
                }
                else if (this.correctSteps.length == 0) {
                    // Go to for the next round
                    this.displayTable[rowIndex][colIndex].isClickedCorrect = true;
                    setTimeout(() => that.displayTable[rowIndex][colIndex].isClickedCorrect = false, 200);
                    this.displayMsg = "Next round! Please wait...";
                    this.clickEnabled = false;
                    this.isAgainDisabled = true;
                    this.nextRoundEffect();
                    this.gameRound++;
                    this.numberOfSteps++;
                    this.generateTable();
                }
                else {
                    this.displayTable[rowIndex][colIndex].isClickedCorrect = true;
                    setTimeout(() => that.displayTable[rowIndex][colIndex].isClickedCorrect = false, 200);
                }
            }
        },
        nextRoundEffect() {
            let that = this;
            this.nextRoundFlag = true;
            setTimeout(() => that.nextRoundFlag = false, 200);
            setTimeout(() => that.nextRoundFlag = true, 400);
            setTimeout(() => that.nextRoundFlag = false, 600);
        },
        gameOverEffect() {
            let that = this;
            this.gameOverFlag = true;
            setTimeout(() => that.gameOverFlag = false, 1000);
        },
        resetGame() {
          this.isGameStarted = false; 
          this.gameRound = 1; 
          this.level = 1; 
          this.tableSize = 2; 
          this.numberOfSteps = 1; 
          this.correctSteps = []; 
          this.currCorrectSteps = [];
          this.timeplay = 0;
          clearInterval(this.intervalGameStart);
          this.getHighScore();
        },
        getHighScore() {
          this.highScoreMsg = "Loading the highscores...";
          this.highScores = [];
          httpRequest.get('/highscore?level=' + this.level)
                .then((response) => {
                  if (response.data && response.data.data) {
                    this.highScores = response.data.data;
                  }
                  if (this.highScores.length == 0) {
                    this.highScoreMsg = "No highscores data";
                  }
                });
        },
        saveHighScore() {
            let username = prompt("Please enter your name", "");
            if (username) {
              let postData = {
                username: username,
                round: this.gameRound - 1,
                timeplay: this.timeplay
              };

              httpRequest.post('/highscore?level=' + this.level, postData)
                .then((response) => {
                  if (response.data && response.data.data) {
                    this.highScores = response.data.data;
                  }

                });
            }
        },
    },
    mounted: function () {
        this.getClientInfo();
        this.getHighScore();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.login-image {
  margin-top: 10%;
  width: 270px;
}
.footer {
  position: absolute;
  right: 0;
  left: 0;
  padding: 1rem;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}
.start-button {
  margin-top: 10%;
  height: 50px;
  color: black;
  background-color: rgb(122, 122, 167);
  widows: 50px;
  cursor: pointer;
}
.game-container table{
  color: white;
  margin-left: auto;
  margin-right: auto;
}
.game-container .td200{
  cursor: pointer;
  width: 200px;
  border: 5px solid black;
  height: 200px;
  background-color: white;
}
.game-container .td100{
  cursor: pointer;
  width: 100px;
  border: 5px solid black;
  height: 100px;
  background-color: white;
}
.game-container .td50{
  cursor: pointer;
  width: 50px;
  border: 5px solid black;
  height: 50px;
  background-color: white;
}
.hightlightstep {
  background-color: red;
}
.lighting-up {
  background-color: yellow !important;
}
.is-clicked-correct {
  background-color: green !important;
}
.is-clicked-error {
  background-color: red !important;
}
.button-active {
  color: white;
  background: red;
  font-weight: bold;
  border: 2px solid red;
}
.congratulation {
  background-color: rgb(7, 233, 7);
  border: 2px solid rgb(7, 233, 7);
}
.game-over {
  background-color: red;
}


#highscores {
  margin-top: 50px;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 450px;
}

#highscores td, #highscores th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

#highscores th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #6f98c1;
;
  color: white;
}
</style>
