<template>
  <div class="home-page">
    <img class="login-image" alt="Logo" src="../assets/tmn_white_logo.png" />
    <h1>Welcome to TMNThanh Website.</h1>
    <br />
    <br />
    <div class="footer">
      <p> Contact me through <a href="mailto:admin@tmn.asia">admin@tmn.asia</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-page",
  methods: {
    getClientInfo() {
      //TODO:
    }
  },
  mounted: function() {
    this.getClientInfo();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.login-image {
  margin-top: 10%;
  width: 270px;
}
.footer {
  position: absolute;
  right: 0;
  left: 0;
  padding: 1rem;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}
</style>
