import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import App from './App.vue'
import Homepage from "@/components/Homepage.vue";
import DiffLetters from "@/components/games/DiffLetters.vue";
import Memories from "@/components/games/Memories.vue";
import './registerServiceWorker'


const routes = [
  {
    path: "/",
    component: Homepage
  },
  {
    path: "/home",
    component: Homepage
  },
  {
    path: "/games/diff-letters",
    component: DiffLetters
  },
  {
    path: "/games/memories",
    component: Memories
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

const mainApp = createApp(App);
mainApp.use(router);
mainApp.mount('#app');
