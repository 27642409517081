import axios from 'axios'
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, getToken, ReCaptchaEnterpriseProvider } from 'firebase/app-check'

const app = initializeApp({
  apiKey: "AIzaSyAgUmbPFyDffvZufjLGOmRJ8KgNpntday4",
  authDomain: "tmnthanh-website.firebaseapp.com",
  projectId: "tmnthanh-website",
  storageBucket: "tmnthanh-website.appspot.com",
  messagingSenderId: "870829324942",
  appId: "1:870829324942:web:eb2262240693dc56e067df",
  measurementId: "G-P0PQBWKWCY"
});

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
//self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6LdSUL0hAAAAAM47u-HdGtUK0s5Wo19qDYZRvRVi'),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});


const request = axios.create({
  baseURL: 'https://asia-southeast1-tmnthanh-website.cloudfunctions.net/apis',
})

request.interceptors.request.use(
  async request => {
    let appCheckTokenResponse;
    try {
        appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
    } catch (err) {
        // Handle any errors if the token was not retrieved.
        return;
    }

    request.headers['X-Firebase-AppCheck'] = appCheckTokenResponse.token;
    return request
  },
  error => {
    return Promise.reject(error)
  }
)


export default request